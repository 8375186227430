










import Vue from 'vue';
export default Vue.extend({
  name: 'userHandbook',
  // created() {
  //   this.open();
  // },
  methods: {
    // open() {
    //   window.open(
    //     'https://ysf.oss.yunsuanfang.com/publics/operation_manual/%E4%BA%91%E7%AE%97%E6%88%BFV2.0%E5%B9%B3%E5%8F%B0%E7%94%A8%E6%88%B7%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C_20220310165528.pdf',
    //     '_black'
    //   );
    // },
  },
});
